<template>
  <div>
    <base-table ref="mallListTable"
                id="mallListTable"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :addHeight="-35">
      <template #toolbar>
        <div>
          <h4 class="text-lg font-bold">小程序商城在售商品</h4>
          <div class="flex justify-between">
            <a-tabs type="card"
                    @change="handlerTypeChange"
                    :activeKey="activeKey">
              <a-tab-pane key="" tab="全部"></a-tab-pane>
              <a-tab-pane v-for="item in typeList"
                          :key="item.type_id"
                          :tab="item.type_name"></a-tab-pane>
              <div slot="tabBarExtraContent">
                <a-button class="ml-2 p-0" type="link"
                          @click="handlerEditType">编辑分类</a-button>
              </div>
            </a-tabs>
            <div>
              <template v-if="!isEdit">
                <a-button type="primary" @click="handlerEditGoods">编辑商品</a-button>
              </template>
              <template v-else>
                <a-button v-if="activeKey" type="primary" 
                          @click="isShowAddGoods = true">添加商品</a-button>
                <a-button class="ml-3" type="danger"
                          @click="handlerEditGoodsSave">保存</a-button>
                <a-button class="ml-3" type="dashed"
                          @click="handlerEditGoodsCancel">取消</a-button>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template #cover_url="{ text }">
        <img :src="text+cutQuery(50,50)"
             style="height: 50px" />
      </template>

      <template #operation="{ record, index }">
        <a-button class="p-0" type="link"
                  @click="handlerDetail(record.goods_id)">详情</a-button>
        <a-button class="p-0 ml-3" type="link"
                  v-if="isEdit"
                  @click="handlerDelete(index)">删除</a-button>
      </template>
    </base-table>
    <type-edit-modal v-if="isShowType"
                     :show.sync="isShowType"
                     :typeList="typeList"
                     @ok="handlerEditTypeOk" />
    <add-goods-modal v-if="isShowAddGoods"
                     :typeId="activeKey"
                     :show.sync="isShowAddGoods"
                     :selectKeys="selectKeys"
                     @ok="handlerAddGoods" />
  </div>
</template>

<script>
import Sortable from "sortablejs"

import TypeEditModal from "./components/type-edit-modal.vue"
import AddGoodsModal from "./components/add-goods-modal.vue"

import pageData from "./columns"
import { cutQuery } from '@/utils/ossImg'

import {
  getMallTypeList,
  getMallAllGoodsList,
  updateMallAllGoodsSortList,
  getMallGoodsList,
  editMallTypeGoods,
  getGoodsPriceList
} from "@/api/mall.js"

import { filterList } from '@/utils/index'

export default {
  components: {
    TypeEditModal,
    AddGoodsModal,
  },
  data () {
    return {
      ...pageData,
      isShowType: false,
      isEdit: false,
      isShowAddGoods: false,
      activeKey: "",
      total: 0,
      typeList: [],
      tableData: [],
      selectKeys: [],

      sort_table: null,
      loading: false,
    }
  },
  async mounted () {
    this.initData()
  },
  methods: {
    cutQuery,
    async initData () {
      const { data, code } = await getMallTypeList()
      if (code == 0) {
        this.typeList = data.list
        // 默认选中
        if (this.$route.query.typeId) {
          this.activeKey = parseInt(this.$route.query.typeId)
          await this.initGoodsData()
          this.isEdit = true
          this.getGoodsList(this.$route.query.goodsId)
          this.$nextTick(() => {
            this.rowDrop()
          })
        } else {
          // this.activeKey = data.list[0].type_id
          this.initGoodsData()
        }
      }
    },
    async initGoodsData () {
      this.isEdit = false
      this.stopRowDrop()
      let res = null
      if(this.activeKey){
        res = await getMallGoodsList({ type_id: this.activeKey })
      }else{
        res = await getMallAllGoodsList()
      }
      this.tableData = res.data.list
      this.selectKeys = res.data.list.map((el) => {
        return el.goods_id
      })
    },

    async getGoodsList (ids) {
      getGoodsPriceList({ goods_ids: ids }).then(res => {
        this.tableData = filterList(res.data.list.concat(this.tableData), 'goods_id')
        this.selectKeys = this.tableData.map((el) => {
          return el.goods_id
        })
      })
    },

    handlerAddGoods (rows) {
      let ids = rows.map((el) => el.goods_id)
      if (rows.length > 0) {
        getGoodsPriceList({ goods_ids: ids.join(",") }).then(res => {
          if(res.code === 0 && res.data.list.length>0){
            this.tableData = res.data.list.concat(this.tableData)
          }
        })
      }
    },

    handlerTypeChange (e) {
      this.activeKey = e
      this.initGoodsData()
    },

    // 分类ok
    handlerEditTypeOk (rows) {
      this.typeList = rows
    },

    handlerEditGoods () {
      // 是新增
      // if (this.isEdit) {
      //   this.isShowAddGoods = true
      // } else {
        this.isEdit = true
        this.$nextTick(() => {
          this.rowDrop()
        })
      // }
    },
    handlerEditGoodsSave () {
      if(this.loading) return

      const params = { type_id: this.activeKey }
      params.goods_data = JSON.stringify(
        this.selectKeys.map((item) => {
          return {
            goods_id: item,
          }
        })
      )
      this.loading = true
      if(this.activeKey){
        editMallTypeGoods(params).then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success("已保存")
            this.initGoodsData()
            this.isEdit = false
            this.stopRowDrop()
          }
        })
      }else{
        updateMallAllGoodsSortList(params).then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success("已保存")
            this.initGoodsData()
            this.isEdit = false
            this.stopRowDrop()
          }
        })
      } 
    },
    handlerEditGoodsCancel () {
      this.isEdit = false
      this.stopRowDrop()
    },
    handlerDelete (index) {
      this.tableData.splice(index, 1)
      this.selectKeys.splice(index, 1)
    },
    handlerDetail (id) {
      this.$router.push({
        path: "/goods-edit",
        query: { type: "detail", id: id },
      })
    },
    handlerEditType () {
      this.isShowType = true
    },
    //行拖拽
    rowDrop () {
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
      const tbody = document.querySelector(
        "#mallListTable .ant-table .ant-table-body .ant-table-tbody"
      )
      const _this = this
      this.sort_table = Sortable.create(tbody, {
        group: ".ant-table-row",
        handle: ".ant-table-row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          const currKey = _this.selectKeys.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
          _this.selectKeys.splice(newIndex, 0, currKey)
        },
      })
    },
    stopRowDrop(){
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
    },

  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
