// 裁剪图片
export function cutQuery(width, height) {
    return _query({
        w: width,
        h: height
    })
}

function _query(opts){
    if(opts.w > 0 && opts.h > 0){
        return '?x-oss-process=image/resize,m_fill,limit_0,w_'+opts.w+',h_'+opts.h
    }

    return ''
}
