<template>
  <a-modal
    :visible.sync="show"
    title="添加商品"
    destroyOnClose
    width="45%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="type"
                               label="产品属性">
              <a-select v-model="searchForm.type"
                        placeholder="产品属性"
                        style="width:100%;">
                <a-select-option value>全部</a-select-option>
                <a-select-option v-for="item in Object.keys(goodsType)"
                                 :value="item"
                                 :key="item">{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item prop="goods_name"
                               label="商品名称">
              <a-input allowClear
                       v-model="searchForm.goods_name"
                       placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button @click="handlerSearch"
                        type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div>
      <base-table ref="goodsTable"
                  :columnsData="columns"
                  style="margin-left:-10px;"
                  rowKey="goods_id"
                  :row-selection="rowSelection"
                  :tableData="tableData"
                  :customHeight="200"
                  :total="total"
                  :getListFunc="initData"
                  :page.sync="searchForm.page"
                  :pageSize.sync="searchForm.page_count">
        <div slot="footer">
          新增
          <span class="text-red-500">{{ selectRows.length }}</span>项
        </div>
      </base-table>
    </div>
  </a-modal>
</template>

<script>
import { getGoodsList } from "@/api/goods"
import { goodsType, formatGoodsType } from "@/utils/type";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: [String, Number],
    },
    selectKeys: {
      type: Array,
    },
  },
  data () {
    return {
      goodsType,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      searchForm: {
        goods_name: "",
        type: "",
        status: 1,
        add_time_start: "",
        add_time_end: "",
        page: 1,
        page_count: 30,
        goods_create_time: [],
      },
      columns: [
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "产品属性",
          dataIndex: "type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
      ],
      tableData: [],
      total: 0,
      selectRows: [],
      rowSelection: {
        selectedRowKeys: this.selectKeys,
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id)
            this.selectRows.push(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.goods_id
            )
            this.selectRows = this.selectRows.filter((el) => el.goods_id != record.goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            )
            this.selectRows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.selectRows = []
          }
        },
      },
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data } = await getGoodsList(this.searchForm)
      this.tableData = data.list.filter((item) => {
        return !this.selectKeys.includes(item.goods_id)
      })
      
      this.total = data.total_count
    },
    // 保存
    handleOk () {
      if(this.selectRows.some(el=>el.type==4)){
        this.$message.info("不能选择茶饮")
        return
      }

      // const params = { type_id: this.typeId }

      // params.goods_data = JSON.stringify(
      //   this.$refs.goodsTable.rowSelection.selectedRowKeys.map((item) => {
      //     return {
      //       goods_id: item,
      //     }
      //   })
      // )

      // editMallTypeGoods(params).then((res) => {
      //   if (res.code === 0) {
      //     this.$message.success("新增商品成功！")
      //     this.$emit("update:show", false)
      //     this.$emit("ok", this.rowSelection.selectedRowKeys)
      //   }
      // })

      this.$emit("update:show", false)
      this.$emit("ok", this.selectRows)
    },
    handlerSearch () {
      this.page = 1
      this.initData()
    },
    resetForm () {
      this.$refs.searchRef.resetFields()
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>